import React from "reactn"
import { graphql } from "gatsby"
import { Layout } from "../../../../../components/layoutRoot"
import Product from "../../../../../components/product"
export default function CurrentProduct({ location, data }) {
  
  return (    
    <Product location={location} data={data}/>
  )
}

CurrentProduct.Layout = Layout

export const query = graphql`
  query($id: String!) {
    siteData: datoCmsSite  {
      ...SiteData
    }
    shipping: datoCmsShipping {
      ...ShippingData
    }
    upcycleShipping: datoCmsUpcycleshipping {
      ...UpcycleShippingData
    }
    productInformation: allDatoCmsProductInformation {
      nodes {
        name
        code
        careAndInfo {
          detail
        }
        endOfLife {
          detail
        }
        tagsAnkerstore {
          detail
        }
        careDetails {
          detail
        }
      }
    }
    staticProduct: shopifyProduct(id: { eq: $id }) {
      ...ProductData      
    }
  }
`